
import React, { lazy } from 'react'

const Text = lazy(() => import('./paragraphs/Text'))
const Image = lazy(() => import('./paragraphs/Image'))
const TextImage = lazy(() => import('./paragraphs/TextImage'))
const HeaderHero = lazy(() => import('./paragraphs/HeaderHero'))
const Group = lazy(() => import('./paragraphs/Group'))
const Gallery = lazy(() => import('./paragraphs/Gallery'))
const Banner = lazy(() => import('./paragraphs/Banner'))

const Paragraph = ({ paragraph, parent }) => {
  const child = parent !== false
  return (
    <>
      { (() => {
        switch (paragraph?.bundle) {
          case 'text':  return <Text paragraph={paragraph} parent={parent} />
          case 'image': return <Image paragraph={paragraph} parent={parent} />
          case 'text_image': return <TextImage paragraph={paragraph} parent={parent} />
          case 'gallery': return <Gallery paragraph={paragraph} parent={parent} />
          case 'banner': return <Banner paragraph={paragraph} parent={parent} />
          case 'header_hero': return <HeaderHero paragraph={paragraph} child={child} /> 
          case 'group': return <Group paragraph={paragraph} child={child} /> 
          default: return <div></div>
        }
      })()}
    </>
  )
}

export const ParagraphLayout = ({ children, className, parent }) => {
  return (
    <div className={`w-full animate-fade-in-slow  ${parent ? 'py-0' : 'py-[8vw] odd:bg-lightest'} ${className}`}>
      { children }
    </div>
  )
}

export default Paragraph