import React, { useState, Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'
import { createBrowserRouter, RouterProvider, Outlet, Navigate } from 'react-router-dom'
import { ModalContainer } from "react-modal-global"
import './assets/styles.scss'
import { useLoader } from './Utils'

import Modal from './components/modal/Modal'
// import ProtectedRoute from './components/ProtectedRoute'
import Content from './screens/Content'
import Chat from './screens/Chat'
import ChatCollection from './screens/ChatCollection'
import ShablonCollection from './screens/ShablonCollection'
import ShablonForm from './screens/ShablonForm'
import Shablon from './screens/Shablon'
import Frontpage from './screens/Frontpage'
import Signin from './screens/Signin'
import Signup from './screens/Signup'
import Document from './screens/Document'
import DocumentCollection from './screens/DocumentCollection'
import Sidebar from './partials/Sidebar'
import Header from './partials/Header'


const queryClient = new QueryClient()

// const Content = lazy(() => import('./screens/Content'))
// const Chats = lazy(() => import('./screens/Chats'))
// const Chat = lazy(() => import('./screens/Chat'))
// const ShablonCollection = lazy(() => import('./screens/ShablonCollection'))
// const ShablonForm = lazy(() => import('./screens/ShablonForm'))
// const Shablon = lazy(() => import('./screens/Shablon'))
// const Frontpage = lazy(() => import('./screens/Frontpage'))

const Layout = () => {
  const { data: user, isLoading } = useLoader('user')
  const [sidebarOpen, setSidebarOpen] = useState(false);

  if (isLoading) {
    return <div>Lade Benutzerdaten ...</div>
  }

  if (!user?.uid) {
    return <Navigate to="/signin" replace />
  }



  return (
    <div className='flex h-[100dvh] overflow-hidden '>
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} variant="v2" />
      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white dark:bg-gray-900">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} variant="v3" />
        <main className="grow flex col">
          <Outlet  />
        </main>
      </div>
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element:  <Frontpage />,
      },
      {
        path: 'chat',
        children: [
          {
            index: true,
            element: <ChatCollection />,
          },
          {
            path: 'add',
            element: <Chat />,
          },
          {
            path: ':uuid',
            element: <Chat />,
          },
        ],
      },
      {
        path: 'shablon',
        children: [
          {
            index: true,
            element: <ShablonCollection />,
          },
          {
            path: 'add',
            children: [
              {
                index: true,
                element: <ShablonForm />,
              }
            ],
          },
          {
            path: ':uuid',
            children: [
              {
                index: true,
                element: <Shablon />,
              },
              {
                path: 'edit',
                element: <ShablonForm />,
              },
            ],
          },
        ],
      },
      {
        path: 'doc',
        children: [
          {
            index: true,
            element: <DocumentCollection />,
          },
          {
            path: 'add',
            children: [
              {
                index: true,
                element: <Document />,
              }
            ],
          },
          {
            path: ':uuid',
            children: [
              {
                index: true,
                element: <Document />,
              },
              {
                path: 'edit',
                element: <Document />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'signin',
    element: <Signin />,
  },
  {
    path: 'signup',
    element: <Signup />,
  },
  {
    path: '*',
    element: <Content />,
  },
])

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
        <Suspense fallback={<div />}>
          <RouterProvider router={router} />
          <ModalContainer controller={Modal} />
        </Suspense>
      </HelmetProvider>
    </QueryClientProvider>
  )
}

export default App