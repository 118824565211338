// import React, { lazy, Suspense } from 'react'
import { useLocation, Link } from 'react-router-dom'
import { useLoader, formatDate } from '../Utils'

import LazyTeaser from '../atoms/LazyTeaser'
import LoadingSpinner from '../atoms/LoadingSpinner'

const ChatCollection = () => {
  const { data: chats } = useLoader('chats')
  return (
    <div className='col items-center'>
      <h1 className='my-8'>Chats</h1>
      <div className='container w-full flex flex-wrap hexs'>
        <div className='col gap-2 '>
          { chats && (chats).map((chat, i) => <LazyTeaser className='h-14' key={i}><ChatTeaser uuid={chat.uuid} /></LazyTeaser>) }
        </div>
      </div>
    </div>
 
  )
}


const ChatTeaser = ({ uuid }) => {
  const { pathname } = useLocation()
  const alias = `/chat/${uuid}`
  const { data: chat, isLoading } = useLoader('chat', { uuid: uuid })
  console.log(chat);
  
  return (
    <div className={`relative h-full border-neutral-200 border hover:bg-neutral-100 transition-all rounded-md ${alias === pathname ? 'font-bold bg-lightest' : 'hover:bg-lightest'}`}>
      { isLoading && <div className='absolute inset-3 flex justify-center items-center animate-fade-in-fast'><LoadingSpinner className='h-full' /></div> }
      <div>
        <Link to={`/chat/${chat?.uuid}`} className='col w-full p-2'>
          <div className='text-xs'>{ formatDate(chat?.created, 'short', 'de-DE') || '-' }</div>
          <div className='text-xs'>{chat?.bundle}</div>
          <div className='text-sm'>{chat?.messages_count || '-'}</div>
        </Link>
      </div>
    </div>
  )
}

export default ChatCollection