import { AiFillLinkedin, AiFillOpenAI } from 'react-icons/ai'
 
const ShablonCategory = ({ category }) => {
  return (
    <div className=' '>
      {(() => {
        switch (category?.field_icon) {
          case 'one':  return <AiFillLinkedin className="text-main-600 dark:text-gray-500 text-4xl" />
          default: return <AiFillOpenAI className="text-main-600 dark:text-gray-500 text-4xl" />
        }
      })()}  
    </div>
  )
}

export default ShablonCategory