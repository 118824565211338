import { useLoader } from '../Utils'
import { Link } from 'react-router-dom'
import ShablonCategory from './ShablonCategory'

const ShablonTeaser = ({ uuid }) => {
  const { data: shablon } = useLoader('shablon', { uuid: uuid })

  return (
    <div className='hex'>
      <div className='hexIn'>
        <Link to={`/shablon/${uuid}`} className='hexLink bg-gray-100 dark:bg-gray-800 flex flex-col px-3 pt-6 pb-8 items-center '>
          <div className='text-xs tracking-widest'>
            <ShablonCategory category={shablon?.field_category} /> 
          </div> 
          <div className='flex-grow flex pt-4 pb-10 items-center justify-center w-full text-2xl/4-m/l   text-gray-700 dark:text-gray-300  text-center tracking-wider suppercase'>{ shablon?.label }</div> 
          {/* <div className='p-1 text-xs tracking-widest text-gray-400 rotate-hex-left absolute bottom-7 left-4 sbg-slate-500 '>{ formatDate(shablon?.created, 'short', 'de-DE') }</div>  */}
        </Link>
      </div>
    </div>
  )
}

export default ShablonTeaser