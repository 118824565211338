import React, { useState } from 'react'
import { Link, Navigate } from "react-router-dom";
import { useLoader, useUserLogin } from '../Utils'
import Form from '../components/Form'
import AuthImage from '../assets/image.png'
import Logo from '../assets/logo192.png'

function Signin() {
  const mutation  = useUserLogin()
  const [errors, setErrors] = useState([])
  const { data: user, isLoading} = useLoader('user')

  if (isLoading) {
    return <div>Lade Benutzerdaten ...</div>
  }

  if (user?.uid) {
    return <Navigate to="/" replace />
  }

  const loginUser = async (values) => {
    setErrors([])

    try {
      await mutation.mutateAsync(values)
    } catch (error) {
      setErrors(error.response.data);
    } 

    return true
  }

  const fields = [  
    {
      id: 'mail',
      type: 'mail',
      label: 'Email Address',
      validation: {
        trim: true,
        required: true,
      }
    },
    {
      id: 'pass',
      type: 'pass',
      label: 'Password',
      validation: {
        min: 3,
        max: 52,
        required: true,
      }
    },
  ]


  return (
    <main className="bg-white dark:bg-gray-900">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-1/2">
          <div className="min-h-[100dvh] h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img className="object-cover object-center w-10 h-10" src={Logo} width="40" height="40" alt="shablon.ai" />
                </Link>
              </div>
            </div>

            <div className="max-w-sm mx-auto w-full px-4 py-8">
              <h1 className="text-3xl text-gray-800 dark:text-gray-100 font-bold mb-6">Welcome back!</h1>

              {/* Form */}
              <Form submitButton='Sign In' onSubmit={loginUser} fields={fields} apiErrors={errors} /> 

              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-gray-100 dark:border-gray-700/60">
                {/* <div className="text-sm">
                  Don’t you have an account?{" "}
                  <Link className="font-medium text-main-500 hover:text-main-600 dark:hover:text-main-400" to="/signup">
                    Sign Up
                  </Link>
                </div> */}
                {/* Warning */}
                {/* <div className="mt-5">
                  <div className="bg-yellow-500/20 text-yellow-700 px-3 py-2 rounded">
                    <svg className="inline w-3 h-3 shrink-0 fill-current mr-2" viewBox="0 0 12 12">
                      <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                    </svg>
                    <span className="text-sm">To support you during the pandemic super pro features are free until March 31st.</span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {/* Image */}
        <div className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2" aria-hidden="true">
          <img className="object-cover object-center w-full h-full" src={AuthImage} width="760" height="1024" alt="Authentication" />
        </div>
      </div>
    </main>
  );
}

export default Signin;
