import React, { useState } from 'react'
import { useLoader } from '../Utils'
import ShablonTeaser from '../atoms/ShablonTeaser'

const ShablonCollection = () => {
  const [category, setCategory] = useState('all')
  const { data: shablons } = useLoader('shablons', { cat: category })
  const { data: terms, isLoading } = useLoader('terms', { vid: 'shablon_categories' })

  return (
    <div className='col items-center'>
      <h1 className='my-8'>Shablons</h1>
      <div className='flex items-center gap-5 py-3'>
        <button onClick={() => setCategory('all')}>All</button>
        { !isLoading && Object.keys(terms).map(key => <button onClick={() => setCategory(terms[key].tid)} key={key}>{terms[key].name}</button>) }
      </div>
      <div className='container w-full flex flex-wrap hexs'>
          {shablons && (shablons).map((shablon, i) => <ShablonTeaser uuid={shablon.uuid} key={i} />)}
      </div>
    </div>
  )
}

export default ShablonCollection