import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from 'react-query'
import { useLoader, postToApi } from '../Utils'

import { EditorContent, useEditor  } from '@tiptap/react'
import StarterKit from '@tiptap/starter-kit'
import Markdown from 'react-markdown'

const Document = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { uuid } = useParams()
  const [streamedText, setStreamedText] = useState('')

  const { data: chat, isLoading } = useLoader('chat', { uuid: uuid })
  console.log(chat);

  const editor = useEditor({
    extensions: [StarterKit],
    // content: chat?.field_content,
  })

  useEffect(() => {
    if (!isLoading) {
      editor.commands.setContent(chat?.field_content)
    }
  }, [chat, isLoading, editor.commands])

  
  const {mutate: sendChat} = useMutation(async (chatData) => {
    console.log(chatData);
    const { data: newChat } = await postToApi(`chat`, chatData)
    return newChat
  }, {
    onMutate: async () => { 
      await queryClient.cancelQueries([`chats`])
    },
    onSuccess: (respone) => {
      queryClient.setQueryData([`chat`, respone.chat.uuid], () => respone.chat) 
      // queryClient.setQueryData([`chat`, respone.chat.uuid, 'messages'], (messages = []) => {
      //   messages.push(respone.messages.client)
      //   messages.push(respone.messages.assistent)
      //   return messages;
      // }) 
      console.log(respone);
      
      navigate(`/doc/${respone.chat.uuid}`, { replace: true })
    },
  })

  const saveDoc = (tokens) => {
    sendChat({
      assistent: 'chatgpt',
      chat: chat?.uuid,
      mode: 'stream',
      bundle: 'document',
      field_content: editor.getHTML(),
      // message: {}
    }, {})
    // endRef.current.scrollIntoView({ behavior: 'smooth' });
    return true
  }

  const handleRewriteClick =  () => {
    const { from, to } = editor.state.selection

    const selectedText = editor.state.doc.textBetween(from, to, ' ')
    if (!selectedText) return

    const encodedText = encodeURIComponent(selectedText)
    const eventSource = new EventSource(`/api/chat/stream?t=${encodedText}`)

    // let start = from
    let text = ''
    // let html = ''

    eventSource.onopen = () => {
      // editor.chain().focus().deleteRange({ from, to }).run()
    }

    eventSource.onmessage = (event) => {
      if (event.data === '[DONE]') {
        eventSource.close()
        return
      } 

      text += event.data

      text = text.replaceAll("<br />", "\n")

      setStreamedText(text)

      // queryClient.setQueryData([`message`, 'uuid'], (old = '' ) => old + event.data);
      
      // try {
      //   preview
      //     .chain()
      //     .focus()
      //     .clearContent()
      //     // .insertContent(event.data)
      //     .insertContent(text)
      //     .run()
      // } catch (error) {
      // }

    }

    eventSource.onerror = (error) => {
      console.error('EventSource failed:', error)
      eventSource.close()
    }
  }

  return (
    <div className='grow  col'>
    <div className='field-editor grow  relative'>
      <ul className='toolbar sticky top-16 bg-gray-300 z-20 before:absolute before:inset-0 before:bg-gray-300 before:-z-50'>
        <li onClick={handleRewriteClick} >
          Rewrite This Part
        </li>
        <li onClick={saveDoc} >
          save
        </li>
        {/* You can add your other 2 buttons here, e.g. "Summarize", "Expand", etc. */}
      </ul>
      {  !isLoading  &&  <EditorContent editor={editor} className='prose mx-auto'/>}
    </div>
      {/* <EditorContent editor={preview} className='prose hidden' /> */}
      <div className='border absolute'>
        <Markdown className='prose '>
        {  streamedText }
        </Markdown>
        {/* { parseHTML(streamedText) } */}
      </div>
    </div>
  )
}

export default Document