import React, { useState } from 'react'
import * as Yup from 'yup'
import { Formik, Form as FormBase } from 'formik'
import { SimpleField, TextareaField, SelectField, PasswordField, PasswordConfirmField, EditorField, ShablonEditorField, HiddenField, CheckboxField } from './FormFields'

const createFieldValidations = (field, validationType) => {
  return Object.entries(field.validation).reduce((validation, [rule, value]) => {
    switch (rule) {
      case 'min':       return validation[rule](value, `${field.label} must be at least ${value} characters`)
      case 'max':       return validation[rule](value, `${field.label} must be max ${value} characters`)
      case 'required':  return validation[rule](`${field.label} is required`)
      case 'email':     return validation[rule](`Invalid email address`)
      case 'positive':  return validation[rule](`${field.label} must be positive`)
      case 'trim':      return validation[rule]()
      case 'lowercase': return validation[rule]()
      case 'uppercase': return validation[rule]()
      default: return null
    }
  }, Yup[validationType]());
}

function Form({ fields, onSubmit, className, submitButton, apiErrors = {} }) {
  const [submitedValues, setSubmitedValues] = useState(false)

  if (!fields.length) return
  let initialValues = {}
  let validationSchema = Yup.object();

  fields.forEach(field => {
    initialValues[field.id] = field.initial || ''

    if (!field.validation) return;

    let validations = {}
    switch (field.type) {
      case 'number':
        validations[field.id] = createFieldValidations(field, 'number')
        break;

      case 'mail':
        field.validation.email = true
        validations[field.id] = createFieldValidations(field, 'string')
        break;

      case 'text':
      case 'select':
      case 'textarea':
      case 'pass':
      case 'editor':
        validations[field.id] = createFieldValidations(field, 'string')
        break;

      case 'pass-confirm':
        validations[field.id] = createFieldValidations(field, 'string')
        validations[`${field.id}-confirm`] = validations[field.id].oneOf([Yup.ref(field.id), null], `${field.label} must match`)
        break;
      default:
        return;
    }

    validationSchema = validationSchema.shape(validations);
  })
 
  // const _fieldChange = (key, value) => {
  //   fieldChange && fieldChange(key, value)
  // }

  const _onSubmit = (values) => {
    setSubmitedValues(values)

    return onSubmit && onSubmit(values)
  }

  return (
    <div className={className}>
      <Formik onSubmit={_onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {({ isSubmitting, values, errors = {}, touched, setFieldValue }) => {

        // const disabled = isSubmitting || !Object.keys(touched).length  || Object.keys(errors).length || Object.keys(apiErrors).length  
        const disabled = isSubmitting || Object.keys(errors).length || Object.keys(apiErrors).length  
 
        
        return (
          <FormBase>
            <div className='form'>
              {fields.map((field, i) => {
                const name = field.id

                const allErrors = {
                  ...errors,
                  ...Object.keys(apiErrors)
                    .filter(name => values[name] === submitedValues[name])
                    .reduce((obj, name) => ({ ...obj, [name]: apiErrors[name] }), {})
                }

                const props = {
                  name: field.id,
                  label: field.label,
                  placeholder: field.placeholder,
                  required: field.validation?.required ,
                  value: values?.[name],
                  error: allErrors?.[name],
                  touched: touched?.[name],
                }
              
                switch (field.type) {
                  case 'hidden':
                    return <HiddenField {...{ name }} key={i} />
                  case 'text':
                    return <SimpleField type='text' {...props} key={i} />
                  case 'number':
                    return <SimpleField type='number' {...props} key={i} />
                  case 'mail':
                    return <SimpleField type='email' {...props} key={i} />
                  case 'textarea':
                    return <TextareaField {...props} key={i} />
                  case 'select':
                    return <SelectField options={field.options} {...props} key={i} />
                  case 'checkbox':
                    return <CheckboxField  {...props} key={i} />  
                  case 'pass':
                    return <PasswordField {...props} key={i} />
                  case 'pass-confirm':
                    return <PasswordConfirmField {...props} key={i} />
                  case 'editor':
                    return <EditorField {...{...props, setFieldValue }} key={i} />
                  case 'editor-shablon':
                    return <ShablonEditorField {...{...props, setFieldValue }} key={i} />
                  default:
                    return <div key={i}></div>
                }
              })}

              <div className='buttons'>
                <button className='button' disabled={disabled} type="submit">
                  { submitButton }
                </button>
              </div>

            </div>
          </FormBase>
        )
      }}
      </Formik>
    </div>
  )
}

export default Form