import React, { useRef } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams, Link } from 'react-router-dom'
import { AiFillEdit } from 'react-icons/ai'
import { useLoader, postToApi  } from '../Utils'

import LazyTeaser from '../atoms/LazyTeaser'
import ShablonFormFull from '../components/ShablonFormFull'

import MessageChatGPT from '../partials/MessageChatGPT'
import MessageShablon from '../partials/MessageShablon'

const Shablon = () => {
  const queryClient = useQueryClient()
  const { uuid } = useParams()
  const endRef = useRef(null)
  const { data: shablon } = useLoader('shablon', { uuid: uuid })

  const { mutate: sendChat } = useMutation(async (chat) => {
    const { data: newChat } = await postToApi(`chat`, chat)
    return newChat
  }, {
    onMutate: async () => { 
      await queryClient.cancelQueries([`chats`])
    },
    onSuccess: (respone) => {
      queryClient.setQueryData([`chat`, respone.chat.uuid], () => (respone.chat)) 
      // queryClient.setQueryData([`chats`], (old = []) => [respone.chat, ...old]) 
      // ToDo > save only the chat uuid
      queryClient.setQueryData([`shablon`, shablon.id, `chats`], (old = []) => [respone.chat, ...old]) 

      queryClient.setQueryData([`chat`, respone.chat.uuid, 'messages'], (messages = []) => {
        messages.push(respone.messages.client)
        messages.push(respone.messages.assistent)
        return messages;
      }) 

      // navigate(`/chat/${respone.chat.uuid}`, { replace: true })
    },
  })

  const submitDrompt = (tokens) => {
    const data = {
      assistent: 'chatgpt',
      mode: 'stream',
      bundle: 'shablon',
      shablon: shablon.uuid,
      message: {
        bundle: 'shablon',
        shablon: shablon.uuid,
        tokens: tokens,
      }
    }

    sendChat(data, {})
    endRef.current.scrollIntoView({ behavior: 'smooth' });
    return true
  }

  return (
    <div className='col items-center '>
      <div className='container w-full col items-center mt-16 '>
        {/* <ShablonCategory category={shablon?.field_category} /> */}
        <h1 className='text-3xl relative mt-2 mb-4'>
          {shablon?.label || 'Create new shablon'}
          <Link to={`/shablon/${shablon?.uuid}/edit`} className='text-xl absolute bottom-1 -right-7'><AiFillEdit /></Link>
        </h1>
        <div className='w-full max-w-2xl'>
          <ShablonFormFull shablon={shablon} onSubmit={submitDrompt} className='pt-10   ' />
        </div>
      </div>
      <div ref={endRef} className='-mb-4 h-0' />
      { shablon?.id && <ShablonChatList shablon={shablon}   />  }
    </div>
  )
}

const ShablonChatList = ({shablon}) => {
  const { data: chats } = useLoader('shablonChats', { id: shablon.id })

  return (
    <div className='py-20 w-full'>
      { chats && (chats).map((chat, i) => <LazyTeaser className='min-h-96  ' key={i}><ChatTeaser uuid={chat.uuid} /></LazyTeaser>) }
    </div>
  )
}

const ChatTeaser = ({ uuid }) => {
  const { data: chat } = useLoader('chat', { uuid: uuid })
  
  return (
    <div className=' w-full col items-center   border-gray-200 pb-5'>
      <div className='py-10 w-full container max-w-4xl'>
        {/* <div className='text-xs'>{ formatDate(chat?.created, 'short', 'de-DE') || '-' }</div> */}
        <ShablonMessages uuid={uuid} />
        <div className='col items-center mt-3'>
          <Link to={`/chat/${chat?.uuid}`} className='mt-5 p-2 rounded-lg bg-gray-500 text-white text-sm'>Weiter Chatten</Link>
        </div>
      </div>
    </div>
  )
}
 
const ShablonMessages = ({ uuid }) => {
  const { data: messages } = useLoader('messages', { uuid: uuid })
  return (
    <div className='w-full col items-stretch gap-6' >
      {messages && (messages).map((message, i) => {
          switch (message?.bundle) {
            case 'openai_chatgpt':  return <MessageChatGPT uuid={message.uuid} key={i} />
            case 'shablon':         return <MessageShablon message={message} key={i} />
            default:                return ''
          }
      })}
    </div>
  )
}

export default Shablon